<template>
  <div class="calc">
    <button
      class="calc__info-toggle"
      type="button"
      @click="showInfo()"
    >
      <span aria-hidden>i</span>
    </button>
    <span
      class="calc__info"
      :class="{ 'calc__info--show': displayInfo }"
    >
      If you're mixing / producing music you want to keep your reverbs, delays
      and sometimes compressor in sync with the tempo of the song. Using a
      millisecond tempo calculator helps you do just that. Insert your tempo
      and the notation you want to get the right milliseconds.
      Please note this is based on 4/4 timing.
    </span>

    <!-- Milliseconds -->
    <div class="row no-gutters justify-content-center">
      <div class="col">
        <div class="calc__result">
          <div class="calc__millseconds">
            <span>{{ millisecondsDisplay() }}</span>
            <span v-if="showMilliseconds()" class="calc__measurment">ms</span>
            <span v-if="showSeconds()" class="calc__measurment">s</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Tempo -->
    <div class="row justify-content-between align-items-end">
      <div class="col-12 col-md-5">
        <div class="calc__tempo">
          <button
            type="button"
            aria-label="Decrease temp by 1"
            class="button-increment"
            @click="increment(-1)"
          >
            <span class="sr-only">Decrease by one</span>
            <span class='button-increment__element'>
              <span class="text--no-select" aria-hidden>-</span>
            </span>
          </button>
          <div class="input">
            <input
              v-model="tempo"
              id="tempo"
              type="number"
              class="input__element"
              @change="validateTempo"
            />
            <label
              for="tempo"
              class="input__label"
            >
              <span class="sr-only">Tempo</span>
            </label>
          </div>
          <button
            type="button"
            aria-label="Increase temp by 1"
            class="button-increment"
            @click="increment(1)"
          >
            <span class="sr-only">Increase by one</span>
            <span class="button-increment__element">
              <span class="text--no-select" aria-hidden>+</span>
            </span>
          </button>
        </div>
        <div class="calc__label" aria-hidden="true">Tempo</div>
      </div>

      <!-- Note Options -->
      <div class="col-12 col-md-7">
        <div class="calc__notes">
          <template v-for="(value, note, index) of notes">
            <div
              :key="index"
              class="radio calc__note"
            >
              <input
                :id="note"
                v-model="notation"
                type="radio"
                name="notation"
                :value="value"
                class="radio__element"
              >
              <label
                :for="note"
                class="radio__label"
              >
                {{ note }}
              </label>
            </div>
          </template>
        </div>
        <div class="calc__label">Note</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Calculator',
  props: {
    notes: {
      type: Object,
      default() {
        return {
          '1/64': 0.0625,
          '1/32': 0.125,
          '1/16': 0.25,
          '1/8': 0.5,
          '1/4': 1,
          '1/2': 2,
          '1/1': 4,
        };
      },
    },
  },
  data() {
    return {
      displayInfo: false,
      tempo: parseFloat(120),
      notation: 0.5,
    };
  },
  computed: {
    milliseconds() {
      return (60000 / (parseFloat(this.tempo) / this.notation));
    },
  },
  methods: {
    increment(increment) {
      this.tempo = parseFloat(this.tempo) + increment;
      this.validateTempo();
    },
    validateTempo() {
      if (parseFloat(this.tempo) < 1) this.tempo = 1;
    },
    millisecondsDisplay() {
      const inMilliseconds = this.milliseconds.toFixed(0);
      const inSeconds = (this.milliseconds / 1000).toFixed(2);
      return this.milliseconds >= 1000 ? inSeconds : inMilliseconds;
    },
    showInfo() {
      this.displayInfo = !this.displayInfo;
    },
    showMilliseconds() {
      return this.tempo > 0 && this.milliseconds < 1000;
    },
    showSeconds() {
      return this.milliseconds > 1000 && this.milliseconds !== Infinity;
    },
  },
};
</script>
